import $ from 'jquery-slim';

class Switch
{
    constructor($element)
    {
        this.$collapse = $($element.attr('data-target'));

        this.$element = $element

        $element.on('change', this.change.bind(this));
    }

    change()
    {
        this.$collapse.collapse(this.$element.is(':checked') ? 'show' : 'hide');
    }
}

class OptSwitch
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        

        let $switchs = $('input[type="checkbox"][data-toggle="toggle"][data-target]');

        let switchs = []
        
        for (let i = 0; i < $switchs.length; i++) switchs.push(new Switch($switchs.eq(i)));

    }

}

const _switch = new OptSwitch();






